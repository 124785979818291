import {
  CREATE_CAREGIVER,
  CREATE_CAREGIVER_FAILURE,
  CREATE_CAREGIVER_SUCCESS,
  FETCH_CAREGIVER,
  FETCH_CAREGIVER_FAILURE,
  FETCH_CAREGIVER_SUCCESS,
  UPDATE_CAREGIVER,
  UPDATE_CAREGIVER_FAILURE,
  UPDATE_CAREGIVER_SUCCESS,
  SET_SINGLE_CAREGIVER,
  GET_CAREGIVER_PAYRATE,
  GET_CAREGIVER_PAYRATE_FALIUR,
  GET_CAREGIVER_PAYRATE_SUCCESS,
} from "./caregover.type";

const initialState = {
  addCaregiver: {
    loading: false,
    data: null,
  },
  caregiver: {
    loading: false,
    data: null,
  },
  updateCaregiver: {
    loading: false,
    data: null,
  },
  signleCaregiver: null,
  caregiverPayrate: {
    loading: false,
    data: null,
  },
};

export default function caregiverReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_CAREGIVER:
      return {
        ...state,
        addCaregiver: {
          loading: true,
        },
      };
    case CREATE_CAREGIVER_SUCCESS:
      return {
        ...state,
        addCaregiver: {
          loading: false,
          data: action.payload,
        },
      };
    case CREATE_CAREGIVER_FAILURE:
      return {
        ...state,
        addCaregiver: {
          loading: false,
          error: action.payload,
        },
      };
    case FETCH_CAREGIVER:
      return {
        ...state,
        caregiver: {
          loading: true,
        },
      };
    case FETCH_CAREGIVER_SUCCESS:
      return {
        ...state,
        caregiver: {
          loading: false,
          data: action.payload,
        },
      };
    case FETCH_CAREGIVER_FAILURE:
      return {
        ...state,
        caregiver: {
          loading: false,
          error: action.payload,
        },
      };
    case UPDATE_CAREGIVER:
      return {
        ...state,
        updateCaregiver: {
          loading: true,
        },
      };
    case UPDATE_CAREGIVER_SUCCESS:
      return {
        ...state,
        updateCaregiver: {
          loading: false,
          data: action.payload,
        },
      };
    case UPDATE_CAREGIVER_FAILURE:
      return {
        ...state,
        updateCaregiver: {
          loading: false,
          error: action.payload,
        },
      };
    case SET_SINGLE_CAREGIVER:
      return {
        ...state,
        signleCaregiver: action.payload,
      };
    case GET_CAREGIVER_PAYRATE:
      return {
        ...state,
        caregiverPayrate: {
          loading: true,
        },
      };
    case GET_CAREGIVER_PAYRATE_SUCCESS:
      return {
        ...state,
        caregiverPayrate: {
          loading: false,
          data: action.payload,
        },
      };
    case GET_CAREGIVER_PAYRATE_FALIUR:
      return {
        ...state,
        caregiverPayrate: {
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
}
