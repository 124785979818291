import React from "react";
import { Route, Redirect } from "react-router-dom";
import jwt_decode from "jwt-decode";

export default function PrivateRoute({ component: Component, ...rest }) {
  let user = null;
  const queryParameters = new URLSearchParams(window.location.search);
  const AuthToken = queryParameters.get("jwt");
  const showAppBar = queryParameters.get("showAppBar") ?? true;
  const showSideBar = queryParameters.get("showSideBar") ?? true;

  if (localStorage.getItem("auth-token")) {
    user = jwt_decode(localStorage.getItem("auth-token"));
  } else {
    user = null;
  }

  const pathname = window.location.pathname;

  return (
    <Route
      {...rest}
      render={(props) => {
        return user ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: {
                from: props.location,
                pathname: pathname,
                token: AuthToken,
                showAppBar: showAppBar,
                showSideBar: showSideBar,
              },
            }}
          />
        );
      }}
      exact
    />
  );
}
