import {
  FETCH_AGENCY_PROFILE,
  FETCH_AGENCY_PROFILE_FALIUR,
  FETCH_AGENCY_PROFILE_SUCCESS,
} from "./Agency.type";

const initialState = {
  agencyDetails: {
    loading: false,
    data: null,
  },
};

export default function agencyReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_AGENCY_PROFILE:
      return {
        ...state,
        agencyDetails: {
          loading: true,
        },
      };
    case FETCH_AGENCY_PROFILE_SUCCESS:
      return {
        ...state,
        agencyDetails: {
          loading: false,
          data: action.payload,
        },
      };
    case FETCH_AGENCY_PROFILE_FALIUR:
      return {
        ...state,
        agencyDetails: {
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
}
