import React, { useEffect } from "react";
import {
  Paper,
  Grid,
  colors,
  Typography,
  TextField,
  Button,
  Snackbar,
} from "@material-ui/core";
import logo from "../../assets/logo/logo.png";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clientUserLogin, createUser, checkToken } from "../../Api/api";
import {
  getAuth,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from "firebase/auth";
import { app } from "../../firebase/firebase";
import {
  userSignUp,
  clientUserByToken,
  clientUserById,
} from "../../Redux/user/user.action";

export default function Login({ location }) {
  const history = useHistory();
  const [userData, setuserData] = React.useState({
    client_user_email: "",
    client_user_password: "",
  });
  const [loginError, setloginError] = React.useState("");
  const [snackbar, setsnackbar] = React.useState(false);
  const auth = getAuth(app);
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const agencydetails = state?.agency?.agencyDetails?.data?.response[0];
  const queryParameters = new URLSearchParams(window.location.search);
  const AuthToken = queryParameters.get("jwt");
  const showAppBar = queryParameters.get("showAppBar") ?? true;
  const showSideBar = queryParameters.get("showSideBar") ?? true;

  useEffect(() => {
    const signIn = async (user) => {
      try {
        const { data } = await createUser(user);
        if (data?.status === "success") {
          // console.log("logged in");
          localStorage.removeItem("userData");
          // history.push("/login");
          dispatch(userSignUp(user));
        } else if (data?.status === "fail") {
          setloginError(data?.message);
          setsnackbar(true);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (isSignInWithEmailLink(auth, window.location.href)) {
      const localuser = JSON.parse(localStorage.getItem("userData"));
      console.log(localuser);
      signIn(localuser);
      signInWithEmailLink(
        auth,
        localuser?.client_user_email,
        window.location.href
      );
    }
  }, []);

  useEffect(() => {
    if (AuthToken) {
      checkToken(AuthToken).then((res) => {
        if (res.data?.response) {
          localStorage.setItem("auth-token", AuthToken);
          dispatch(clientUserByToken());
          history.push({
            pathname: "/dashboard",
            search: `?jwt=${AuthToken}&showAppBar=${showAppBar}&showSideBar=${showSideBar}`,
          });
        }
      });
    }
  }, [AuthToken, history, dispatch]);

  useEffect(() => {
    if (location.state?.token) {
      checkToken(location.state?.token).then((res) => {
        if (res.data?.response) {
          localStorage.setItem("auth-token", location.state?.token);
          dispatch(clientUserByToken());
          history.push({
            pathname: location.state?.pathname
              ? location.state?.pathname
              : "/dashboard",
            search: `?jwt=${location.state?.token}&showAppBar=${location.state?.showAppBar}&showSideBar=${location?.state?.showSideBar}`,
          });
        }
      });
    }
  }, [location]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await clientUserLogin(userData);
      if (data?.status === "success") {
        localStorage.setItem("auth-token", data.token);
        dispatch(clientUserByToken());
        dispatch(clientUserById(data?.response?._id));
        if (data?.response?.is_admin) {
          if (agencydetails) {
            history.push("/dashboard");
          } else {
            history.push("/set-agency-profile");
          }
        } else {
          history.push("/dashboard");
        }
      } else if (data?.status === "fail") {
        setloginError(data.message);
        setsnackbar(true);
      }
      setuserData({
        client_user_email: "",
        client_user_password: "",
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Paper style={{ height: "100vh" }}>
      <Snackbar
        open={snackbar}
        onClose={() => setsnackbar(false)}
        autoHideDuration={2000}
        message="Quantity Changed"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "rgba(23, 36, 55, 1)",
            margin: "70px 20px",
          }}
        >
          <div
            style={{
              color: "white",
              fontSize: "16px",
              padding: "10px",
            }}
          >
            {loginError}
          </div>
        </div>
      </Snackbar>
      <Grid container>
        <Grid
          item
          sm={5}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ maxWidth: 360 }}>
            <img alt="" src={logo} width={100} />
            <Typography
              variant="h5"
              style={{ fontWeight: "bold", fontSize: "2rem" }}
            >
              Sign in to your account
            </Typography>

            <form onSubmit={handleSubmit} style={{ margin: "2rem 0" }}>
              <TextField
                size="small"
                fullWidth
                variant="outlined"
                label="Email Address"
                value={userData.client_user_email}
                onChange={(e) =>
                  setuserData({
                    ...userData,
                    client_user_email: e.target.value,
                  })
                }
                required
              />
              <TextField
                size="small"
                fullWidth
                variant="outlined"
                label="Password"
                type="password"
                style={{ margin: "2rem 0" }}
                value={userData.client_user_password}
                onChange={(e) =>
                  setuserData({
                    ...userData,
                    client_user_password: e.target.value,
                  })
                }
                required
              />
              <Button
                style={{ marginTop: "1rem" }}
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
              >
                Login
              </Button>
            </form>
          </div>
        </Grid>
        <Grid item sm={7}>
          <div
            style={{
              width: "100%",
              height: "100vh",
              background: colors.amber[800],
            }}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}
