export const CREATE_CAREGIVER = "CREATE_CAREGIVER";
export const CREATE_CAREGIVER_SUCCESS = "CREATE_CAREGIVER_SUCCESS";
export const CREATE_CAREGIVER_FAILURE = "CREATE_CAREGIVER_FAILURE";
export const FETCH_CAREGIVER = "FETCH_CAREGIVER";
export const FETCH_CAREGIVER_SUCCESS = "FETCH_CAREGIVER_SUCCESS";
export const FETCH_CAREGIVER_FAILURE = "FETCH_CAREGIVER_FAILURE";
export const DELETE_CAREGIVER = "DELETE_CAREGIVER";
export const UPDATE_CAREGIVER = "UPDATE_CAREGIVER";
export const UPDATE_CAREGIVER_SUCCESS = "UPDATE_CAREGIVER_SUCCESS";
export const UPDATE_CAREGIVER_FAILURE = "UPDATE_CAREGIVER_FAILURE";
export const SET_SINGLE_CAREGIVER = "SET_SINGLE_CAREGIVER";
export const GET_CAREGIVER_PAYRATE = "GET_CAREGIVER_PAYRATE";
export const GET_CAREGIVER_PAYRATE_SUCCESS = "GET_CAREGIVER_PAYRATE_SUCCESS";
export const GET_CAREGIVER_PAYRATE_FALIUR = "GET_CAREGIVER_PAYRATE_FALIUR";
