import {
  CREATE_AGENT,
  CREATE_AGENT_FALIUR,
  CREATE_AGENT_SUCCESS,
  CREATE_SUB_AGENT,
  CREATE_SUB_AGENT_FALIUR,
  CREATE_SUB_AGENT_SUCCESS,
  FETCH_AGENT,
  FETCH_AGENT_FALIUR,
  FETCH_AGENT_SUCCESS,
  FETCH_SUB_AGENT,
  FETCH_SUB_AGENT_FALIUR,
  FETCH_SUB_AGENT_SUCCESS,
  DELETE_SUB_AGENT,
  UPDATE_SUB_AGENT,
  UPDATE_SUB_AGENT_FALIUR,
  UPDATE_SUB_AGENT_SUCCESS,
  UPDATE_MAIN_AGENT,
  UPDATE_MAIN_AGENT_FALIUR,
  UPDATE_MAIN_AGENT_SUCCESS,
} from "./Agent.type";

const initialState = {
  addAgent: {
    loading: false,
    data: null,
  },
  addSubAgent: {
    loading: false,
    data: null,
  },
  agent: {
    loading: false,
    data: null,
  },
  subAgent: {
    loading: false,
    data: null,
  },
  updateSubAgent: {
    loading: false,
    data: null,
  },
  updateAgent: {
    loading: false,
    data: null,
  },
};

export default function agentReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_AGENT:
      return {
        ...state,
        addAgent: {
          loading: true,
        },
      };
    case CREATE_AGENT_SUCCESS:
      return {
        ...state,
        addAgent: {
          loading: false,
          data: action.payload,
        },
      };
    case CREATE_AGENT_FALIUR:
      return {
        ...state,
        addAgent: {
          loading: false,
          error: action.payload,
        },
      };
    case CREATE_SUB_AGENT:
      return {
        ...state,
        addSubAgent: {
          loading: true,
        },
      };
    case CREATE_SUB_AGENT_SUCCESS:
      return {
        ...state,
        addSubAgent: {
          loading: false,
          data: action.payload,
        },
      };
    case CREATE_SUB_AGENT_FALIUR:
      return {
        ...state,
        addSubAgent: {
          loading: false,
          error: action.payload,
        },
      };
    case FETCH_AGENT:
      return {
        ...state,
        agent: {
          loading: true,
        },
      };
    case FETCH_AGENT_SUCCESS:
      return {
        ...state,
        agent: {
          loading: false,
          data: action.payload,
        },
      };
    case FETCH_AGENT_FALIUR:
      return {
        ...state,
        agent: {
          loading: false,
          error: action.payload,
        },
      };
    case FETCH_SUB_AGENT:
      return {
        ...state,
        subAgent: {
          loading: true,
        },
      };
    case FETCH_SUB_AGENT_SUCCESS:
      return {
        ...state,
        subAgent: {
          loading: false,
          data: action.payload,
        },
      };
    case FETCH_SUB_AGENT_FALIUR:
      return {
        ...state,
        subAgent: {
          loading: false,
          error: action.payload,
        },
      };
    case DELETE_SUB_AGENT:
      return {
        ...state,
        deleteSubAgent: action.payload,
      };
    case UPDATE_SUB_AGENT:
      return {
        ...state,
        updateSubAgent: {
          loading: true,
        },
      };
    case UPDATE_SUB_AGENT_SUCCESS:
      return {
        ...state,
        updateSubAgent: {
          loading: false,
          data: action.payload,
        },
      };
    case UPDATE_SUB_AGENT_FALIUR:
      return {
        ...state,
        updateSubAgent: {
          loading: false,
          error: action.payload,
        },
      };
    case UPDATE_MAIN_AGENT:
      return {
        ...state,
        updateAgent: {
          loading: true,
        },
      };
    case UPDATE_MAIN_AGENT_SUCCESS:
      return {
        ...state,
        updateAgent: {
          loading: false,
          data: action.payload,
        },
      };
    case UPDATE_MAIN_AGENT_FALIUR:
      return {
        ...state,
        updateAgent: {
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
}
