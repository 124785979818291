export const CREATE_ASIGNEE = "CREATE_ASIGNEE";
export const CREATE_ASIGNEE_SUCCESS = "CREATE_ASIGNEE_SUCCESS";
export const CREATE_ASIGNEE_FALIUR = "CREATE_ASIGNEE_FALIUR";
export const FETCH_ASIGNEE = "FETCH_ASIGNEE";
export const FETCH_ASIGNEE_SUCCESS = "FETCH_ASIGNEE_SUCCESS";
export const FETCH_ASIGNEE_FALIUR = "FETCH_ASIGNEE_FALIUR";
export const DELETE_ASIGNEE = "DELETE_ASIGNEE";
export const UPDATE_ASIGNEE = "UPDATE_ASIGNEE";
export const UPDATE_ASIGNEE_SUCCESS = "UPDATE_ASIGNEE_SUCCESS";
export const UPDATE_ASIGNEE_FALIUR = "UPDATE_ASIGNEE_FALIUR";
