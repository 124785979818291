export const CREATE_AGENT = "CREATE_AGENT";
export const CREATE_AGENT_SUCCESS = "CREATE_AGENT_SUCCESS";
export const CREATE_AGENT_FALIUR = "CREATE_AGENT_FALIUR";
export const CREATE_SUB_AGENT = "CREATE_SUB_AGENT";
export const CREATE_SUB_AGENT_SUCCESS = "CREATE_SUB_AGENT_SUCCESS";
export const CREATE_SUB_AGENT_FALIUR = "CREATE_SUB_AGENT_FALIUR";
export const FETCH_AGENT = "FETCH_AGENT";
export const FETCH_AGENT_SUCCESS = "FETCH_AGENT_SUCCESS";
export const FETCH_AGENT_FALIUR = "FETCH_AGENT_FALIUR";
export const FETCH_SUB_AGENT = "FETCH_SUB_AGENT";
export const FETCH_SUB_AGENT_SUCCESS = "FETCH_SUB_AGENT_SUCCESS";
export const FETCH_SUB_AGENT_FALIUR = "FETCH_SUB_AGENT_FALIUR";
export const DELETE_SUB_AGENT = "DELETE_SUB_AGENT";
export const UPDATE_SUB_AGENT = "UPDATE_SUB_AGENT";
export const UPDATE_SUB_AGENT_SUCCESS = "UPDATE_SUB_AGENT_SUCCESS";
export const UPDATE_SUB_AGENT_FALIUR = "UPDATE_SUB_AGENT_FALIUR";
export const DELETE_MAIN_AGENT = "DELETE_MAIN_AGENT";
export const UPDATE_MAIN_AGENT = "UPDATE_MAIN_AGENT";
export const UPDATE_MAIN_AGENT_SUCCESS = "UPDATE_MAIN_AGENT_SUCCESS";
export const UPDATE_MAIN_AGENT_FALIUR = "UPDATE_MAIN_AGENT_FALIUR";
