import {
  ADD_PAYER,
  ADD_PAYER_FAILURE,
  ADD_PAYER_SUCCESS,
  DELETE_PAYER,
  DELETE_PAYER_FAILURE,
  DELETE_PAYER_SUCCESS,
  UPDATE_PAYER,
  UPDATE_PAYER_FAILURE,
  UPDATE_PAYER_SUCCESS,
  FETCH_PAYER,
  FETCH_PAYER_FAILURE,
  FETCH_PAYER_SUCCESS,
} from "./Payer.type";

const initialState = {
  addPayer: {
    loading: false,
    data: null,
  },
  payerList: {
    loading: false,
    data: null,
  },
  updatePayer: {
    loading: false,
    data: null,
  },
};

export default function payerReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_PAYER:
      return {
        ...state,
        addPayer: {
          loading: true,
        },
      };
    case ADD_PAYER_SUCCESS:
      return {
        ...state,
        addPayer: {
          loading: false,
          data: action.payload,
        },
      };
    case ADD_PAYER_FAILURE:
      return {
        ...state,
        addPayer: {
          loading: false,
          error: action.payload,
        },
      };
    case FETCH_PAYER:
      return {
        ...state,
        payerList: {
          loading: true,
        },
      };
    case FETCH_PAYER_SUCCESS:
      return {
        ...state,
        payerList: {
          loading: false,
          data: action.payload,
        },
      };
    case FETCH_PAYER_FAILURE:
      return {
        ...state,
        payerList: {
          loading: false,
          error: action.payload,
        },
      };
    case UPDATE_PAYER:
      return {
        ...state,
        updatePayer: {
          loading: true,
        },
      };
    case UPDATE_PAYER_SUCCESS:
      return {
        ...state,
        updatePayer: {
          loading: false,
          data: action.payload,
        },
      };
    case UPDATE_PAYER_FAILURE:
      return {
        ...state,
        updatePayer: {
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
}
