import { initializeApp } from "firebase/app";
import "firebase/auth";

const config = {
  apiKey: "AIzaSyD5WJS7xGMZdMpKss7BOLfyT94Ek9rvuZE",
  authDomain: "caregiver-9a398.firebaseapp.com",
  projectId: "caregiver-9a398",
  storageBucket: "caregiver-9a398.appspot.com",
  messagingSenderId: "970388574268",
  appId: "1:970388574268:web:7cada92f1f6e5822c7b339",
  measurementId: "G-LMYRELC7SB",
};
export const app = initializeApp(config);
