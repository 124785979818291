import { createMuiTheme, colors, ThemeProvider } from "@material-ui/core";
import React, { lazy, Suspense } from "react";
import {
  // BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { PropagateLoader } from "react-spinners";
import { lazily } from "react-lazily";
// import Sidebar from "./components/Sidebar/sidebar";
import Login from "./pages/login/Login";
import PrivateRoute from "./components/PrivateRoute";
import ResetPassword from "./pages/login/resetPassword";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { clientUserById, clientUserByToken } from "./Redux/user/user.action";
import { getAgencyDetails } from "./Redux/Agency/Agency.action";
import { getDepartmentList } from "./Redux/DepartmentRedux/department.action";
import { checkToken } from "./Api/api";
// import authclass from "./components/Auth";
const AddPatient = lazy(() => import("./pages/AllPatientPage/AddPatient"));
const PayersPage = lazy(() =>
  import("./pages/SettingsPage/PayersPage/PayersPage")
);
const OfficePage = lazy(() =>
  import("./pages/SettingsPage/OfficePage/OfficePage")
);
const AgencyProfile = lazy(() =>
  import("./pages/SettingsPage/AgencyProfile/AgencyProfile")
);
const AssigneePage = lazy(() =>
  import("./pages/SettingsPage/AsigneePage/AsigneePage")
);
const AgentPage = lazy(() =>
  import("./pages/SettingsPage/AgeentPage/AddAgent")
);
const AddCareGiver = lazy(() =>
  import("./pages/AllCareGiverPage/AddCareGiver")
);
const AllNewPatientList = lazy(() =>
  import("./pages/AllPatientPage/AllNewPatientList")
);
const AllNewCareGiverList = lazy(() =>
  import("./pages/AllCareGiverPage/AllNewCareGiverList")
);
const SignUp = lazy(() => import("./pages/login/SignUp"));
const DashBoard = lazy(() => import("./pages/Dashboard/DashBoard"));
const { NewPassword } = lazily(() => import("./pages/login/NewPassword"));
const Profile = lazy(() => import("./pages/Profile/Profile"));
const ChangePassword = lazy(() => import("./pages/login/ChangePassword"));
const TremsAndConditions = lazy(() =>
  import("./pages/Trems&Conditions/Trems&Conditions")
);
const ApprovedPatient = lazy(() =>
  import("./pages/ApprovedPatient/AllApprovedPatient")
);
const ApprovedPatientDetailsPage = lazy(() =>
  import("./pages/ApprovedPatient/ApprovedPatientDetailsPage")
);
const SetAgencyProfile = lazy(() =>
  import("./pages/SettingsPage/AgencyProfile/SetAgencyProfile")
);
const EvvSubmit = lazy(() => import("./pages/EvvSubmit/EvvSubmit"));
const Visits = lazy(() => import("./pages/Visits/Visits"));
const Report = lazy(() => import("./pages/Report/Report"));
const Billing = lazy(() => import("./pages/Billing/Billing"));
const NewBatch = lazy(() => import("./pages/Billing/NewBatch"));
const AddCaregiverCSV = lazy(() =>
  import("./pages/ApprovedPatient/AddCaregiverCSV")
);
const PatientDetailsModalPage = lazy(() =>
  import("./pages/AllPatientPage/PatientDetailsModalPage")
);
const CaregiverDetailsModalPage = lazy(() =>
  import("./pages/AllCareGiverPage/CaregiverDetailsModalPage")
);

const route = [
  { link: "/add-patient", component: AddPatient },
  { link: "/settings/payers", component: PayersPage },
  { link: "/settings/office", component: OfficePage },
  { link: "/settings/agency-profile", component: AgencyProfile },
  { link: "/settings/coordinator", component: AssigneePage },
  { link: "/settings/agent", component: AgentPage },
  { link: "/add-caregiver", component: AddCareGiver },
  { link: "/all-new-patient", component: AllNewPatientList },
  { link: "/all-new-caregiver", component: AllNewCareGiverList },
  { link: "/dashboard", component: DashBoard },
  { link: "/profile", component: Profile },
  { link: "/change-password", component: ChangePassword },
  { link: "/all-approved-patient", component: ApprovedPatient },
  { link: "/set-agency-profile", component: SetAgencyProfile },
  { link: "/set-agency-profile", component: SetAgencyProfile },
  { link: "/report", component: Report },
  { link: "/visit", component: Visits },
  { link: "/billing", component: Billing },
  { link: "/billing/new-batch", component: NewBatch },
  { link: "/evv-submit", component: EvvSubmit },
  { link: "/patient-details", component: ApprovedPatientDetailsPage },
  { link: "/add-caregiver-csv", component: AddCaregiverCSV },
  { link: "/new-patient-details", component: PatientDetailsModalPage },
  { link: "/caregiver-details", component: CaregiverDetailsModalPage },
];

function App() {
  const state = useSelector((state) => state);
  const queryParameters = new URLSearchParams(window.location.search);
  const AuthToken = queryParameters.get("jwt");
  const dispatch = useDispatch();
  const agencydetails = state?.agency?.agencyDetails?.data?.response[0];
  const themes = createMuiTheme({
    palette: {
      primary: {
        main: state?.auth?.data?.client_user_color_hex_value
          ? state?.auth?.data?.client_user_color_hex_value
          : "#145388",
        contrastText:
          state?.auth?.data?.client_user_color_theme === "dark"
            ? "#ffffff"
            : colors.grey[100],
      },
      secondary: {
        main:
          state?.auth?.data?.client_user_color_theme === "dark"
            ? state?.auth?.data?.client_user_color_hex_value
            : colors.grey[700],
        light:
          state?.auth?.data?.client_user_color_theme === "dark"
            ? "#1e2022"
            : "#ffffff",
      },
    },
    typography: {
      body2: {
        fontSize: "12px",
        color:
          state?.auth?.data?.client_user_color_theme === "dark"
            ? "#ffffff"
            : "#000000",
      },
      body1: {
        fontSize: "12px",
        color:
          state?.auth?.data?.client_user_color_theme === "dark"
            ? "#ffffff"
            : "#3a3a3a",
      },
      h6: {
        fontSize: ".9rem",
        color:
          state?.auth?.data?.client_user_color_theme === "dark"
            ? "#ffffff"
            : "#3a3a3a",
      },
      button: {
        textTransform: "none",
      },
    },
    overrides: {
      MuiTextField: {
        root: {
          "& .floatingLabelFocusStyle": {
            color: colors.grey[600],
          },
        },
      },
      MuiInputLabel: {
        root: {
          color: colors.grey[600],
        },
      },
      MuiButton: {
        // contained: {
        //   color: "#ffffff",
        // },
        containedPrimary: {
          color: "#ffffff",
        },
        // outlined: {
        //   color: "#145388",
        // },
        outlinedPrimary: {
          color: "#145388",
        },
      },
      MuiPaper: {
        elevation1: {
          boxShadow:
            "5px 5px 5px -1px rgb(0 0 0 / 5%), 0px 5px 8px 8px rgb(0 0 0 / 6%), 0px 1px 8px 1px rgb(0 0 0 / 5%)",
        },
      },
    },
  });
  const userByToken = state?.user?.clientUserByToken?.data;

  useEffect(() => {
    dispatch(clientUserByToken());
    dispatch(getAgencyDetails());
    dispatch(getDepartmentList());
  }, [dispatch]);

  useEffect(() => {
    if (userByToken?._id) {
      dispatch(clientUserById(userByToken?._id));
    }
  }, [dispatch, userByToken]);

  useEffect(() => {
    if (agencydetails) {
      window.document.title = agencydetails?.friendly_name;
    }
  }, [agencydetails]);

  useEffect(() => {
    if (AuthToken) {
      checkToken(AuthToken).then((res) => {
        if (res.data?.response) {
          localStorage.setItem("auth-token", AuthToken);
          dispatch(clientUserByToken());
        }
      });
    }
  }, [AuthToken]);

  // window.requestFullscreen();

  // const some = document.getElementById("app");

  // some.requestFullscreen();

  return (
    <Suspense
      fallback={
        <div
          style={{
            // width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <PropagateLoader size={25} color={themes.palette.primary.main} />
        </div>
      }
    >
      <ThemeProvider theme={themes}>
        {/* {state?.auth?.loading ? (
          <div className="loaderBg">
            <CircularProgress
              color={
                state?.auth?.data?.client_user_color_theme === "dark"
                  ? "#ffffff"
                  : colors.grey[400]
              }
            />
          </div>
        ) : ( */}
        <Switch>
          <Route path="/login" component={Login} exact />
          <Route path="/signup" component={SignUp} exact />
          <Route path="/reset-password" component={ResetPassword} exact />
          <Route path="/new-password" component={NewPassword} exact />
          <Route
            path="/trems-conditions"
            component={TremsAndConditions}
            exact
          />
          {route.map((res) => (
            <PrivateRoute path={res.link} component={res.component} exact />
          ))}
          <Redirect strict from="/" to="/add-patient" />
        </Switch>
      </ThemeProvider>
    </Suspense>
  );
}

export default App;
