import {
  FETCH_NEW_PATIENT_LIST,
  FETCH_NEW_PATIENT_LIST_FAILURE,
  FETCH_NEW_PATIENT_LIST_SUCCESS,
  SIGNLE_PATIENT,
  DELETE_PATIENT,
  FETCH_APPROVED_DETAILS,
  FETCH_APPROVED_DETAILS_FALIUR,
  FETCH_APPROVED_DETAILS_SUCCESS,
  FETCH_APPROVED_PATIENT_LIST,
  FETCH_APPROVED_PATIENT_LIST_FAlIUR,
  FETCH_APPROVED_PATIENT_LIST_SUCCESS,
  GET_PAYER_BY_STATUS,
  GET_PAYER_BY_STATUS_FALIUR,
  GET_PAYER_BY_STATUS_SUCCESS,
  GET_PAYER_BY_UPCOMING,
  GET_PAYER_BY_UPCOMING_FALIUR,
  GET_PAYER_BY_UPCOMING_SUCCESS,
  GET_PATIENT_CALENDER_TIMING,
} from "./Patient.type";

const initialState = {
  newPatientList: {
    loading: false,
    data: null,
  },
  signlePatirnt: null,
  patientDelete: null,
  approvedDetails: {
    loading: false,
    data: null,
  },
  approvedPatientList: {
    loading: false,
    data: null,
  },
  singleApprovedPaient: null,
  upcomingPayer: {
    loading: false,
    data: null,
  },
  expiredPayer: {
    loading: false,
    data: null,
  },
  patientCalender: null,
};

export default function patientReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_NEW_PATIENT_LIST:
      return {
        ...state,
        newPatientList: {
          loading: true,
        },
      };
    case FETCH_NEW_PATIENT_LIST_SUCCESS:
      return {
        ...state,
        newPatientList: {
          loading: false,
          data: action.payload,
        },
      };
    case FETCH_NEW_PATIENT_LIST_FAILURE:
      return {
        ...state,
        newPatientList: {
          loading: false,
          error: action.payload,
        },
      };
    case SIGNLE_PATIENT:
      return {
        ...state,
        signlePatirnt: action.payload,
      };
    case DELETE_PATIENT:
      return {
        ...state,
        patientDelete: action.payload,
      };
    case FETCH_APPROVED_DETAILS:
      return {
        ...state,
        approvedDetails: {
          loading: true,
        },
      };
    case FETCH_APPROVED_DETAILS_SUCCESS:
      return {
        ...state,
        approvedDetails: {
          loading: false,
          data: action.payload,
        },
      };
    case FETCH_APPROVED_DETAILS_FALIUR:
      return {
        ...state,
        approvedDetails: {
          loading: false,
          error: action.payload,
        },
      };
    case FETCH_APPROVED_PATIENT_LIST:
      return {
        ...state,
        approvedPatientList: {
          loading: true,
        },
      };
    case FETCH_APPROVED_PATIENT_LIST_SUCCESS:
      return {
        ...state,
        approvedPatientList: {
          loading: false,
          data: action.payload,
        },
      };
    case FETCH_APPROVED_PATIENT_LIST_FAlIUR:
      return {
        ...state,
        approvedPatientList: {
          loading: false,
          error: action.payload,
        },
      };
    case GET_PAYER_BY_STATUS:
      return {
        ...state,
        expiredPayer: {
          loading: true,
        },
      };
    case GET_PAYER_BY_STATUS_SUCCESS:
      return {
        ...state,
        expiredPayer: {
          loading: false,
          data: action.payload,
        },
      };
    case GET_PAYER_BY_STATUS_FALIUR:
      return {
        ...state,
        expiredPayer: {
          loading: false,
          error: action.payload,
        },
      };
    case GET_PAYER_BY_UPCOMING:
      return {
        ...state,
        upcomingPayer: {
          loading: true,
        },
      };
    case GET_PAYER_BY_UPCOMING_SUCCESS:
      return {
        ...state,
        upcomingPayer: {
          loading: false,
          data: action.payload,
        },
      };
    case GET_PAYER_BY_UPCOMING_FALIUR:
      return {
        ...state,
        upcomingPayer: {
          loading: false,
          error: action.payload,
        },
      };
    case GET_PATIENT_CALENDER_TIMING:
      return {
        ...state,
        patientCalender: action.payload,
      };
    default:
      return state;
  }
}
