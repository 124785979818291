import {
  CREATE_ASIGNEE,
  CREATE_ASIGNEE_FALIUR,
  CREATE_ASIGNEE_SUCCESS,
  FETCH_ASIGNEE,
  FETCH_ASIGNEE_FALIUR,
  FETCH_ASIGNEE_SUCCESS,
  DELETE_ASIGNEE,
  UPDATE_ASIGNEE,
  UPDATE_ASIGNEE_FALIUR,
  UPDATE_ASIGNEE_SUCCESS,
} from "./Asignee.type";
import {
  createAsignee,
  fetchAsignee,
  deleteAsignee,
  updateAsignee,
  createUser,
} from "../../Api/api";
import { EditClientUser, getClientUser } from "../user/user.action";

export const addAsignee = (body, type) => (dispatch) => {
  try {
    dispatch({ type: CREATE_ASIGNEE });
    if (type === "Admin") {
      const variables = {
        client_user_first_name: body.client_user_first_name,
        client_user_last_name: body.client_user_last_name,
        client_user_email: body.client_user_email,
        client_user_password: body.client_user_password,
        client_user_company_website_url: body.client_user_company_website_url,
        client_user_company_address: body.client_user_company_address,
        client_user_phone_number: body.client_user_phone_number,
        client_user_organization: body.client_user_organization,
        confirm_client_user_password: body.confirm_client_user_password,
      };
      createUser(variables)
        .then((res) => {
          dispatch({ type: CREATE_ASIGNEE_SUCCESS, payload: res.data });
        })
        .catch((error) => {
          dispatch({ type: CREATE_ASIGNEE_FALIUR, payload: error });
        });
    } else {
      createAsignee(body)
        .then((res) => {
          dispatch({ type: CREATE_ASIGNEE_SUCCESS, payload: res.data });
          dispatch(getAsignee());
        })
        .catch((error) => {
          dispatch({ type: CREATE_ASIGNEE_FALIUR, payload: error });
        });
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: CREATE_ASIGNEE_FALIUR, payload: error });
  }
};

export const getAsignee = () => (dispatch) => {
  try {
    dispatch({ type: FETCH_ASIGNEE });
    fetchAsignee()
      .then((res) => {
        dispatch({ type: FETCH_ASIGNEE_SUCCESS, payload: res.data });
      })
      .catch((error) => {
        dispatch({ type: FETCH_ASIGNEE_FALIUR, payload: error });
      });
  } catch (error) {
    console.log(error);
    dispatch({ type: FETCH_ASIGNEE_FALIUR, payload: error });
  }
};

export const removeAsignee = (id) => async (dispatch) => {
  try {
    const variables = {
      data: {
        _id: id,
      },
    };
    const { data } = await deleteAsignee(variables);
    dispatch({ type: DELETE_ASIGNEE, payload: data });
    dispatch(getAsignee());
  } catch (error) {
    console.log(error);
  }
};

export const asigneeUpdate = (body, type) => (dispatch) => {
  try {
    dispatch({ type: UPDATE_ASIGNEE });
    if (type === "Admin") {
      const variables = {
        client_user_first_name: body.assignee_first_name,
        client_user_last_name: body.assignee_last_name,
        client_user_email: body.assignee_email,
        client_user_company_website_url: body.assignee_company_website_url,
        client_user_company_address: body.assignee_company_address,
        client_user_phone_number: body.assignee_phone_number,
        client_user_organization: body.assignee_organization,
        _id: body?._id,
      };
      dispatch(EditClientUser(variables));
    } else {
      updateAsignee(body)
        .then((res) => {
          dispatch({ type: UPDATE_ASIGNEE_SUCCESS, payload: res.data });
          dispatch(getAsignee());
        })
        .catch((error) => {
          dispatch({ type: UPDATE_ASIGNEE_FALIUR, payload: error });
        });
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: UPDATE_ASIGNEE_FALIUR, payload: error });
  }
};
