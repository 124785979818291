import {
  CREATE_ASIGNEE,
  CREATE_ASIGNEE_FALIUR,
  CREATE_ASIGNEE_SUCCESS,
  FETCH_ASIGNEE,
  FETCH_ASIGNEE_FALIUR,
  FETCH_ASIGNEE_SUCCESS,
  DELETE_ASIGNEE,
  UPDATE_ASIGNEE,
  UPDATE_ASIGNEE_FALIUR,
  UPDATE_ASIGNEE_SUCCESS,
} from "./Asignee.type";

const initialState = {
  addAsignee: {
    loading: false,
    data: null,
  },
  asigneeList: {
    loading: false,
    data: null,
  },
  updateAsignee: {
    loading: false,
    data: null,
  },
};

export default function asigneeReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_ASIGNEE:
      return {
        ...state,
        addAsignee: {
          loading: true,
        },
      };
    case CREATE_ASIGNEE_SUCCESS:
      return {
        ...state,
        addAsignee: {
          loading: false,
          data: action.payload,
        },
      };
    case CREATE_ASIGNEE_FALIUR:
      return {
        ...state,
        addAsignee: {
          loading: false,
          error: action.payload,
        },
      };
    case FETCH_ASIGNEE:
      return {
        ...state,
        asigneeList: {
          loading: true,
        },
      };
    case FETCH_ASIGNEE_SUCCESS:
      return {
        ...state,
        asigneeList: {
          loading: false,
          data: action.payload,
        },
      };
    case FETCH_ASIGNEE_FALIUR:
      return {
        ...state,
        asigneeList: {
          loading: false,
          error: action.payload,
        },
      };
    case DELETE_ASIGNEE:
      return {
        ...state,
        deleteAsignee: action.payload,
      };
    case UPDATE_ASIGNEE:
      return {
        ...state,
        updateAsignee: {
          loading: true,
        },
      };
    case UPDATE_ASIGNEE_SUCCESS:
      return {
        ...state,
        updateAsignee: {
          loading: false,
          data: action.payload,
        },
      };
    case UPDATE_ASIGNEE_FALIUR:
      return {
        ...state,
        updateAsignee: {
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
}
