import {
  FETCH_PROVIDER_DATA,
  FETCH_PROVIDER_DATA_FALIUR,
  FETCH_PROVIDER_DATA_SUCCESS,
} from "./Provider.type";

const initialState = {
  providerData: {
    loading: false,
    data: null,
  },
};

export default function providerReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PROVIDER_DATA:
      return {
        ...state,
        providerData: {
          loading: true,
        },
      };
    case FETCH_PROVIDER_DATA_SUCCESS:
      return {
        ...state,
        providerData: {
          loading: false,
          data: action.payload,
        },
      };
    case FETCH_PROVIDER_DATA_FALIUR:
      return {
        ...state,
        providerData: {
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
}
