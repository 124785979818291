import {
  FETCH_PATIENT_ADMISSION,
  FETCH_PATIENT_ADMISSION_FALIUR,
  FETCH_PATIENT_ADMISSION_SUCCESS,
} from "./PatientActivity.type";

const initialState = {
  patientAdmission: {
    data: null,
    loading: false,
  },
};

export default function patientActivity(state = initialState, action) {
  switch (action.type) {
    case FETCH_PATIENT_ADMISSION:
      return {
        ...state,
        patientAdmission: {
          loading: true,
        },
      };
    case FETCH_PATIENT_ADMISSION_SUCCESS:
      return {
        ...state,
        patientAdmission: {
          loading: false,
          data: action.payload,
        },
      };
    case FETCH_PATIENT_ADMISSION_FALIUR:
      return {
        ...state,
        patientAdmission: {
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
}
