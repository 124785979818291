import {
  CREATE_NEW_ACCOUNT,
  CREATE_NEW_ACCOUNT_FAILURE,
  CREATE_NEW_ACCOUNT_SUCCESS,
  CLIENT_USER_BY_ID,
  CLIENT_USER_BY_TOKEN,
  GET_CLIENT_USER,
  GET_CLIENT_USER_FALIUR,
  GET_CLIENT_USER_SUCCESS,
} from "./user.type";

const initialState = {
  createAccount: {
    loading: false,
    error: null,
  },
  clientUserDetails: {
    data: null,
  },
  clientUserByToken: {
    data: null,
  },
  clientUser: {
    data: null,
    loading: false,
  },
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_NEW_ACCOUNT:
      return {
        ...state,
        createAccount: {
          loading: true,
        },
      };
    case CREATE_NEW_ACCOUNT_SUCCESS:
      return {
        ...state,
        createAccount: {
          loading: false,
          data: action.payload,
        },
      };
    case CREATE_NEW_ACCOUNT_FAILURE:
      return {
        ...state,
        createAccount: {
          loading: false,
          error: action.payload,
        },
      };
    case CLIENT_USER_BY_ID:
      return {
        ...state,
        clientUserDetails: {
          data: action.payload,
        },
      };
    case CLIENT_USER_BY_TOKEN:
      return {
        ...state,
        clientUserByToken: {
          data: action.payload,
        },
      };
    case GET_CLIENT_USER:
      return {
        ...state,
        clientUser: {
          loading: true,
        },
      };
    case GET_CLIENT_USER_SUCCESS:
      return {
        ...state,
        clientUser: {
          loading: false,
          data: action.payload,
        },
      };
    case GET_CLIENT_USER_FALIUR:
      return {
        ...state,
        clientUser: {
          loading: false,
          data: action.payload,
        },
      };
    default:
      return state;
  }
}
