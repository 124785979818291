export default function getCurrentBaseUrlData() {
  const list = [
    {
      hostname_list: ["old.greatcareusa.com"],
      apiBaseUrl: "https://api.greatcareusa.com/api",
    },
    {
      hostname_list: ["old-member.ods2.us"],
      apiBaseUrl: "https://api-member.ods2.us/api",
    },
    {
      hostname_list: ["old.frontliner.care"],
      apiBaseUrl: "https://api.frontliner.care/api",
    },
  ];

  let hostname = window.location.hostname;

  if (hostname === "localhost" || hostname === "127.0.0.1") {
    hostname = list[1].hostname_list[0];
  }

  const result = list.filter((e) => e.hostname_list.includes(hostname));

  return result[0];
}
