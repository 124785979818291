import {
  FETCH_DEPARTMENT_LIST,
  FETCH_DEPARTMENT_LIST_FAILURE,
  FETCH_DEPARTMENT_LIST_SUCCESS,
} from "./department.type";

const initialState = {
  departmentList: {
    data: null,
    loading: false,
  },
};

export default function departmentReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_DEPARTMENT_LIST:
      return {
        ...state,
        departmentList: {
          loading: true,
        },
      };
    case FETCH_DEPARTMENT_LIST_SUCCESS:
      return {
        ...state,
        departmentList: {
          data: action.payload,
          loading: false,
        },
      };
    case FETCH_DEPARTMENT_LIST_FAILURE:
      return {
        ...state,
        departmentList: {
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
}
