import axios from "axios";
import getCurrentBaseUrlData from "./getBabseUrl";

const currentBaseURL = getCurrentBaseUrlData();

const baseURL = currentBaseURL.apiBaseUrl;
// "https://member-dndtech-imsh8.ondigitalocean.app/api";

// https://great-direct-backend-prod-g5whtncwfa-uc.a.run.app
//Payers
export const addPayerDetails = (body) => axios.post(`${baseURL}/payer`, body);
export const getPayers = () => axios.get(`${baseURL}/payer`);
export const deletePayer = (payerId) =>
  axios.delete(`${baseURL}/payer`, payerId);
export const updatePayer = (body) => axios.put(`${baseURL}/payer`, body);
export const createManyPayers = (body) =>
  axios.post(`${baseURL}/add_many_payer`, body);

//office
export const fetchMainOffice = () => axios.get(`${baseURL}/main_office`);
export const fetchSubOffice = () => axios.get(`${baseURL}/sub_office`);
export const createSuboffice = (body) =>
  axios.post(`${baseURL}/sub_office`, body);
export const createMainOffice = (body) =>
  axios.post(`${baseURL}/main_office`, body);
export const updateSuboffice = (body) =>
  axios.put(`${baseURL}/sub_office`, body);
export const deleteSuboffice = (subofficeId) =>
  axios.delete(`${baseURL}/sub_office`, subofficeId);

//Asignee
export const createAsignee = (body) =>
  axios.post(`${baseURL}/add_sub_admin`, body);
export const fetchAsignee = () => axios.get(`${baseURL}/client_user`);
export const deleteAsignee = (body) =>
  axios.delete(`${baseURL}/assignee`, body);
export const updateAsignee = (body) => axios.put(`${baseURL}/assignee`, body);
export const updateAsigneePassword = (body) =>
  axios.post(`${baseURL}/assignee_update_password`, body);

//Agent
export const createAgent = (body) => axios.post(`${baseURL}/main_agent`, body);
export const createSubAgent = (body) =>
  axios.post(`${baseURL}/sub_agent`, body);
export const fetchAgent = () => axios.get(`${baseURL}/main_agent`);
export const fetchSubAgent = () => axios.get(`${baseURL}/sub_agent`);
export const deleteSubAgent = (body) =>
  axios.delete(`${baseURL}/sub_agent`, body);
export const deleteAgent = (body) =>
  axios.delete(`${baseURL}/main_agent`, body);
export const updateAgent = (body) => axios.put(`${baseURL}/main_agent`, body);
export const updateSubAgent = (body) => axios.put(`${baseURL}/sub_agent`, body);
export const subAgentByAgentId = (agentId) =>
  axios.get(`${baseURL}/sub_agent`, agentId);

//Agency Profile
export const uploadAgencyImage = (body) =>
  axios.post(`${baseURL}/add_agency_image`, body);
export const agency = (body, method) =>
  axios[method](`${baseURL}/agency`, body);
export const createNpi = (body) => axios.post(`${baseURL}/npi`, body);
export const createDepertment = (body) =>
  axios.post(`${baseURL}/department `, body);
export const fetchNPI = () => axios.get(`${baseURL}/npi`);
export const fetchDepartment = () => axios.get(`${baseURL}/department`);
export const deleteNPI = (body) => axios.delete(`${baseURL}/npi`, body);
export const deleteDepartment = (body) =>
  axios.delete(`${baseURL}/department`, body);
export const editNPI = (body) => axios.put(`${baseURL}/npi`, body);
export const editDepartment = (body) =>
  axios.put(`${baseURL}/department`, body);

export const createProvider = (body) => axios.post(`${baseURL}/provider`, body);

export const getProvider = (status) =>
  axios.get(`${baseURL}/provider${status}`);

export const createMMIS = (body) => axios.post(`${baseURL}/mmis`, body);
export const getMMIS = (status) => axios.get(`${baseURL}/mmis${status}`);

export const createFEIN = (body) => axios.post(`${baseURL}/fein`, body);
export const getFEIN = (status) => axios.get(`${baseURL}/fein${status}`);

//Caregiver
export const createCaregiver = (body) =>
  axios.post(`${baseURL}/caregiver`, body);
export const fetchCaregiver = (status) =>
  axios.get(`${baseURL}/caregiver/${status}`);
export const deleteCaregiver = (body) =>
  axios.delete(`${baseURL}/caregiver`, body);
export const editCaregiver = (body) => axios.put(`${baseURL}/caregiver`, body);
export const fetchCaregiverById = (id) =>
  axios.get(`${baseURL}/get_caregiver_by_id/${id}`);

export const uploadCaregiverPrifieImage = (body) =>
  axios.post(`${baseURL}/add_caregiver_profile_image`, body);

export const getCaregiverAprovedWorkingHoures = (patientId, id, type) =>
  axios.get(`${baseURL}/get_caregiver_timings/${type}/${patientId}/${id}`);

export const filteredCaregiver = (id, status) =>
  axios.get(
    `${baseURL}/get_all_unassigned_caregivers_by_patient_id/${id}/${status}?page=1&limit=25`
  );

export const getcaregiverPayrateDetails = (id) =>
  axios.get(`${baseURL}/approve_patient_caregivers_pay_rate/${id}`);

export const checkCaregiverSnnNo = (no) =>
  axios.get(`${baseURL}/check_caregiver_ssn/${no}`);

export const createManyCaregiver = (body) =>
  axios.post(`${baseURL}/add_many_caregiver`, body);

export const authCaregiverMobileNumber = (phone) =>
  axios.get(`${baseURL}/check_caregiver_phone/${phone}`);

export const approvedPatientCaregiverId = (id) =>
  axios.get(`${baseURL}/get_all_approved_patient_by_caregiver_id/${id}`);

//user
export const createUser = (body) =>
  axios.post(`${baseURL}/client_user_signup`, body);
export const getClientUsers = () => axios.get(`${baseURL}/client_user`);
export const updateClientUser = (body) =>
  axios.put(`${baseURL}/update_client_user`, body);
export const clientUserLogin = (body) =>
  axios.post(`${baseURL}/client_user_login_email_password`, body);
export const forgotPassword = (body) =>
  axios.post(`${baseURL}/client_user_update_password`, body);
export const clientUserbyId = (userId) =>
  axios.post(`${baseURL}/get_client_user_by_id`, userId);
export const checkClientUser = (body) =>
  axios.post(`${baseURL}/client_user_check_email`, body);
export const removeClientUser = (body) =>
  axios.post(`${baseURL}/delete_client_user`, body);
export const clientUserByType = (type) =>
  axios.post(`${baseURL}/get_client_user_by_type`, type);
export const checkToken = (token) =>
  axios.post(
    `${baseURL}/ClientUserTokenIsValid`,
    {},
    {
      headers: { authtoken: token },
    }
  );

//Patient
export const createPatient = (body) => axios.post(`${baseURL}/patient`, body);
export const fetchPatient = (status, userId) =>
  axios.get(`${baseURL}/patient/${status}`, {
    headers: { clientuserid: userId },
  });
export const fetchPatientById = (id) =>
  axios.get(`${baseURL}/get_patient_by_id/${id}`);
export const deleteManyPatient = (body) =>
  axios.delete(`${baseURL}/delete_many_patient`, body);
export const updatePatient = (body) => axios.put(`${baseURL}/patient`, body);
export const uploadPatientProfileImage = (body) =>
  axios.post(`${baseURL}/add_patient_profile_image`, body);
export const checkMadicaidNo = (no) =>
  axios.get(`${baseURL}/check_medicate_number/${no}`);

export const addAgentToPatient = (body) =>
  axios.post(`${baseURL}/add_agent_to_patient`, body);
export const createPatientNote = (body) =>
  axios.post(`${baseURL}/patient_note`, body);
export const fetchPatientNote = (patientId) =>
  axios.get(`${baseURL}/patient_note/${patientId}`);

export const addManyPatient = (body) =>
  axios.post(`${baseURL}/add_many_patient`, body);

//Approved Patient
export const approvePayerCaregiverWorkingHours = (body) =>
  axios.post(
    `${baseURL}/patient_add_approved_payer_caregiver_working_hours`,
    body
  );
export const getApprovePayerCaregiverWorkingHours = (id) =>
  axios.get(
    `${baseURL}/get_approved_payer_caregiver_working_hours_patient_by_id/${id}`
  );
export const getApprovePayerCaregiverWorkingHoursByCaregiverId = (id) =>
  axios.get(
    `${baseURL}/get_approved_payer_caregiver_working_hours_patient_by_caregiver_id/${id}`
  );
export const updatePayerCaregiverWorkingHours = (body) =>
  axios.put(
    `${baseURL}/update_patient_approved_payer_caregiver_working_hours`,
    body
  );
export const updateApprovePayer = (body) =>
  axios.put(`${baseURL}/patient_update_approved_payer`, body);

//NEW APPROVED
export const approvedPatient = (body) =>
  axios.post(`${baseURL}/approve_patient`, body);

export const fetchApprovedPatient = (type, userId) =>
  axios.get(`${baseURL}/approved_patients/${type}`, {
    headers: { clientuserid: userId },
  });

export const editApprovedPatient = (body) =>
  axios.put(`${baseURL}/update_approved_patient`, body);

export const addCaregiverToPayer = (body) =>
  axios.post(`${baseURL}/add_caregiver_to_approve_patient`, body);

export const addMoreCaregiverToPatient = (body) =>
  axios.post(`${baseURL}/add_more_caregiver_to_approve_patient`, body);

export const getApprovedPatientById = (id) =>
  axios.get(`${baseURL}/approved_patient_by_id/${id}`);

export const addUpcomingPayerToPatien = (body) =>
  axios.post(`${baseURL}/approve_existing_patient_with_new_payer`, body);

export const upadateAdmissionApprovedPatient = (body) =>
  axios.post(`${baseURL}/update_approved_patient_admission_status`, body);

export const getPayeerFromStatus = (type, id) =>
  axios.get(`${baseURL}/approved_patient_current_status/${id}/${type}`);

export const updatePayrateToApprovedCaregiver = (body) =>
  axios.put(`${baseURL}/update_approve_patient_caregiver`, body);

export const updateApprovedTimeAlocation = (body) =>
  axios.put(`${baseURL}/update_approved_patient_allocation_time`, body);

export const addAgentToApprovePatient = (body) =>
  axios.post(`${baseURL}/add_agent_to_approved_patient`, body);

export const approvedManyPatient = (body) =>
  axios.post(`${baseURL}/approve_many_patient`, body);

export const addActivityOfPatient = (body) =>
  axios.post(`${baseURL}/hold_patients`, body);

export const getActivityOfPatient = (id) =>
  axios.get(`${baseURL}/get_hold_patients_by_approved_patient_id/${id}`);

export const updateActivityOfPatient = (body) =>
  axios.put(`${baseURL}/update_hold_patients`, body);

export const deleteActivity = (body) =>
  axios.post(`${baseURL}/delete_hold_patients`, body);

export const checkPatientSSN = (ssn) =>
  axios.get(`${baseURL}/check_patient_ssn/${ssn}`);

export const checkPatientMedicare = (num) =>
  axios.get(`${baseURL}/check_patient_medicare/${num}`);
export const deleteUpcomingPatient = (body) =>
  axios.post(`${baseURL}/delete_upcoming_approved_patient`, body);

//TODO: Approved caregiver
export const removeApprovedCaregiver = (body) =>
  axios.post(`${baseURL}/remove_caregiver_from_approve_patient`, body);

export const updateApprovedCaregiver = (body) =>
  axios.put(`${baseURL}/update_approved_caregiver`, body);

export const getCaregiverCalenderTiming = (patientId, caregiverId) =>
  axios.get(
    `${baseURL}/approved_patients_caregiver_calendar/${patientId}/${caregiverId}`
  );

export const fetchPatientCalenderTiming = (body) =>
  axios.post(`${baseURL}/approved_patients_calendar`, body);

export const addManyCaregiverToApprovedPatient = (body) =>
  axios.post(`${baseURL}/add_many_caregiver_to_approve_patient`, body);

//Document
export const uploadDocument = (body) =>
  axios.post(`${baseURL}/add_image`, body);

//Report
export const getReport = (status) =>
  axios.get(`${baseURL}/approved_patients_report/${status}`);

//Visits
export const fetchVisits = (status) =>
  axios.get(`${baseURL}/clock_in/${status}`);

export const fetchVisitByCaregiver = (caregiverId) =>
  axios.get(`${baseURL}/approved_patients_caregiver_clock_in/${caregiverId}`);

export const fetchVisitByPatient = (patientId) =>
  axios.get(`${baseURL}/get_all_clock_in/${patientId}`);

export const createVisitManualy = (body) =>
  axios.post(`${baseURL}/clock_in`, body);

export const createManyVisitManualy = (body) =>
  axios.post(`${baseURL}/add_many_clock_in`, body);

export const fetchMissedVisitForAllCaregiver = (status) =>
  axios.get(`${baseURL}/get_all_unvisited_caregiver_data/${status}`);

export const updateVisitData = (body) =>
  axios.put(`${baseURL}/update_many_clock_in`, body);

export const updateSingleVisitData = (body) =>
  axios.put(`${baseURL}/clock_in`, body);

export const addManyApprovedClockIn = (body) =>
  axios.post(`${baseURL}/add_many_approved_clock_in`, body);

export const getApprovedClockIn = (status) =>
  axios.get(`${baseURL}/approved_clock_in/${status}`);

export const createManyClockIn = (body) =>
  axios.post(`${baseURL}/add_many_clock_in_from_unvisited_clockin`, body);

//Batch
export const fetchBatchData = (body, status) =>
  axios.post(`${baseURL}/get_all_clock_in_by_many_payer_id/${status}`, body);

export const createBatch = (body) =>
  axios.post(`${baseURL}/create_batch`, body);

export const fetchAllBatch = () => axios.get(`${baseURL}/get_all_batch`);

export const batchDelete = (body) =>
  axios.delete(`${baseURL}/delete_batch`, body);

//Dashboard
export const fetchDashboardData = () =>
  axios.get(`${baseURL}/get_patient_and_caregiver_month_registration_count`);
export const fetchApprovedPatientCurrentStatus = () =>
  axios.get(`${baseURL}/approved_patient_patient_current_status_count`);

//IP Code
export const getIpDetails = () => axios.get("https://ipapi.co/json/");
