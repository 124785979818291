export const ADD_PAYER = "ADD_PAYER";
export const ADD_PAYER_SUCCESS = "ADD_PAYER_SUCCESS";
export const ADD_PAYER_FAILURE = "ADD_PAYER_FAILURE";
export const DELETE_PAYER = "DELETE_PAYER";
export const DELETE_PAYER_SUCCESS = "DELETE_PAYER_SUCCESS";
export const DELETE_PAYER_FAILURE = "DELETE_PAYER_FAILURE";
export const UPDATE_PAYER = "UPDATE_PAYER";
export const UPDATE_PAYER_SUCCESS = "UPDATE_PAYER_SUCCESS";
export const UPDATE_PAYER_FAILURE = "UPDATE_PAYER_FAILURE";
export const FETCH_PAYER = "FETCH_PAYER";
export const FETCH_PAYER_SUCCESS = "FETCH_PAYER_SUCCESS";
export const FETCH_PAYER_FAILURE = "FETCH_PAYER_FAILURE";
