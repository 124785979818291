import {
  FETCH_MAIN_OFFICE,
  CREATE_OFFICE,
  CREATE_OFFICE_FAILURE,
  CREATE_OFFICE_SUCCESS,
  FETCH_SUB_OFFICE,
  FETCH_SUB_OFFICE_FAILURE,
  FETCH_SUB_OFFICE_SUCCESS,
  UPDATE_OFFICE,
  UPDATE_OFFICE_FAILURE,
  UPDATE_OFFICE_SUCCESS,
} from "./office.type";

const initialState = {
  mainOffice: null,
  createOffice: {
    loading: false,
    data: null,
  },
  subOffice: {
    loading: false,
    data: null,
  },
  updateOffice: {
    loading: false,
    data: null,
  },
};

export default function officeReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_MAIN_OFFICE:
      return {
        ...state,
        mainOffice: action.payload,
      };
    case CREATE_OFFICE:
      return {
        ...state,
        createOffice: {
          loading: true,
        },
      };
    case CREATE_OFFICE_SUCCESS:
      return {
        ...state,
        createOffice: {
          loading: false,
          data: action.payload,
        },
      };
    case CREATE_OFFICE_FAILURE:
      return {
        ...state,
        createOffice: {
          loading: false,
          error: action.payload,
        },
      };
    case FETCH_SUB_OFFICE:
      return {
        ...state,
        subOffice: {
          loading: true,
        },
      };
    case FETCH_SUB_OFFICE_SUCCESS:
      return {
        ...state,
        subOffice: {
          loading: false,
          data: action.payload,
        },
      };
    case FETCH_SUB_OFFICE_FAILURE:
      return {
        ...state,
        subOffice: {
          loading: false,
          error: action.payload,
        },
      };
    case UPDATE_OFFICE:
      return {
        ...state,
        updateOffice: {
          loading: true,
        },
      };
    case UPDATE_OFFICE_SUCCESS:
      return {
        ...state,
        updateOffice: {
          loading: false,
          data: action.payload,
        },
      };
    case UPDATE_OFFICE_FAILURE:
      return {
        ...state,
        updateOffice: {
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
}
