import {
  FETCH_BATCH_DATA,
  FETCH_BATCH_DATA_FALIUR,
  FETCH_BATCH_DATA_SUCCESS,
  FETCH_ALL_BATCH,
  FETCH_ALL_BATCH_FALIUR,
  FETCH_ALL_BATCH_SUCCESS,
} from "./Batch.type";

const initialState = {
  batchData: {
    loading: false,
    data: null,
  },
  allBatch: {
    loading: false,
    data: null,
  },
};

export default function batchReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_BATCH_DATA:
      return {
        ...state,
        batchData: {
          loading: true,
        },
      };
    case FETCH_BATCH_DATA_SUCCESS:
      return {
        ...state,
        batchData: {
          loading: false,
          data: action.payload,
        },
      };
    case FETCH_BATCH_DATA_FALIUR:
      return {
        ...state,
        batchData: {
          loading: false,
          error: action.payload,
        },
      };
    case FETCH_ALL_BATCH:
      return {
        ...state,
        allBatch: {
          loading: true,
        },
      };
    case FETCH_ALL_BATCH_SUCCESS:
      return {
        ...state,
        allBatch: {
          loading: false,
          data: action.payload,
        },
      };
    case FETCH_ALL_BATCH_FALIUR:
      return {
        ...state,
        allBatch: {
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
}
