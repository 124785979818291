import {
  CREATE_NEW_ACCOUNT,
  CREATE_NEW_ACCOUNT_FAILURE,
  CREATE_NEW_ACCOUNT_SUCCESS,
  CLIENT_USER_BY_ID,
  CLIENT_USER_BY_TOKEN,
  GET_CLIENT_USER,
  GET_CLIENT_USER_FALIUR,
  GET_CLIENT_USER_SUCCESS,
} from "./user.type";
import {
  createUser,
  clientUserbyId,
  getClientUsers,
  updateClientUser,
  removeClientUser,
} from "../../Api/api";
import jwt_decode from "jwt-decode";
import { getAsignee } from "../Asignee/Asignee.action";

export const userSignUp = (user) => (dispatch) => {
  try {
    dispatch({ type: CREATE_NEW_ACCOUNT });
    createUser(user)
      .then((res) => {
        dispatch({ type: CREATE_NEW_ACCOUNT_SUCCESS, payload: res.data });
      })
      .catch((err) => {
        dispatch({ type: CREATE_NEW_ACCOUNT_FAILURE, payload: err });
      });
  } catch (error) {
    console.log(error);
    dispatch({ type: CREATE_NEW_ACCOUNT_FAILURE, payload: error });
  }
};

export const clientUserById = (id) => async (dispatch) => {
  try {
    const variables = {
      clientuserid: id,
    };
    const { data } = await clientUserbyId(variables);
    dispatch({ type: CLIENT_USER_BY_ID, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const clientUserByToken = () => (dispatch) => {
  try {
    const user = jwt_decode(localStorage.getItem("auth-token"));
    dispatch({ type: CLIENT_USER_BY_TOKEN, payload: user });
  } catch (error) {
    console.log(error);
  }
};

export const getClientUser = () => (dispatch) => {
  try {
    dispatch({ type: GET_CLIENT_USER });
    getClientUsers()
      .then((res) => {
        dispatch({ type: GET_CLIENT_USER_SUCCESS, payload: res.data });
      })
      .catch((error) => {
        dispatch({ type: GET_CLIENT_USER_FALIUR, payload: error });
      });
  } catch (error) {
    console.log(error);
    dispatch({ type: GET_CLIENT_USER_FALIUR, payload: error });
  }
};

export const EditClientUser = (body) => (dispatch) => {
  try {
    updateClientUser(body).then((res) => {
      dispatch(getAsignee());
    });
  } catch (error) {
    console.log(error);
  }
};

export const deleteClientUser = (body) => (dispatch) => {
  try {
    removeClientUser(body).then((res) => {
      dispatch(getAsignee());
    });
  } catch (error) {
    console.log(error);
  }
};
