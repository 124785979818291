export const FETCH_NEW_PATIENT_LIST = "FETCH_NEW_PATIENT_LIST";
export const FETCH_NEW_PATIENT_LIST_SUCCESS = "FETCH_NEW_PATIENT_LIST_SUCCESS";
export const FETCH_NEW_PATIENT_LIST_FAILURE = "FETCH_NEW_PATIENT_LIST_FAILURE";
export const SIGNLE_PATIENT = "SIGNLE_PATIENT";
export const DELETE_PATIENT = "DELETE_PATIENT";
export const FETCH_APPROVED_DETAILS = "FETCH_APPROVED_DETAILS";
export const FETCH_APPROVED_DETAILS_SUCCESS = "FETCH_APPROVED_DETAILS_SUCCESS";
export const FETCH_APPROVED_DETAILS_FALIUR = "FETCH_APPROVED_DETAILS_FALIUR";
export const FETCH_APPROVED_PATIENT_LIST = "FETCH_APPROVED_PATIENT_LIST";
export const FETCH_APPROVED_PATIENT_LIST_SUCCESS =
  "FETCH_APPROVED_PATIENT_LIST_SUCCESS";
export const FETCH_APPROVED_PATIENT_LIST_FAlIUR =
  "FETCH_APPROVED_PATIENT_LIST_FAlIUR";
export const GET_PAYER_BY_STATUS = "GET_PAYER_BY_STATUS";
export const GET_PAYER_BY_STATUS_SUCCESS = "GET_PAYER_BY_STATUS_SUCCESS";
export const GET_PAYER_BY_STATUS_FALIUR = "GET_PAYER_BY_STATUS_FALIUR";
export const GET_PAYER_BY_UPCOMING = "GET_PAYER_BY_UPCOMING";
export const GET_PAYER_BY_UPCOMING_SUCCESS = "GET_PAYER_BY_UPCOMING_SUCCESS";
export const GET_PAYER_BY_UPCOMING_FALIUR = "GET_PAYER_BY_UPCOMING_FALIUR";
export const GET_PATIENT_CALENDER_TIMING = "GET_PATIENT_CALENDER_TIMING";
