export const FETCH_MAIN_OFFICE = "FETCH_MAIN_OFFICE";
export const CREATE_OFFICE = "CREATE_OFFICE";
export const CREATE_OFFICE_SUCCESS = "CREATE_OFFICE_SUCCESS";
export const CREATE_OFFICE_FAILURE = "CREATE_OFFICE_FAILURE";
export const FETCH_SUB_OFFICE = "FETCH_SUB_OFFICE";
export const FETCH_SUB_OFFICE_SUCCESS = "FETCH_SUB_OFFICE_SUCCESS";
export const FETCH_SUB_OFFICE_FAILURE = "FETCH_SUB_OFFICE_FAILURE";
export const UPDATE_OFFICE = "UPDATE_OFFICE";
export const UPDATE_OFFICE_SUCCESS = "UPDATE_OFFICE_SUCCESS";
export const UPDATE_OFFICE_FAILURE = "UPDATE_OFFICE_FAILURE";
export const DELETE_SUB_OFFICE = "DELETE_SUB_OFFICE";
