import {
  FETCH_PATIENT_NOTE,
  FETCH_PATIENT_NOTE_FALIUR,
  FETCH_PATIENT_NOTE_SUCCESS,
} from "./note.type";

const initialState = {
  patientNote: {
    data: null,
    loading: false,
  },
};

export default function noteReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PATIENT_NOTE:
      return {
        ...state,
        patientNote: {
          loading: true,
        },
      };
    case FETCH_PATIENT_NOTE_SUCCESS:
      return {
        ...state,
        patientNote: {
          data: action.payload,
          loading: false,
        },
      };
    case FETCH_PATIENT_NOTE_FALIUR:
      return {
        ...state,
        patientNote: {
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
}
