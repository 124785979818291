import { FETCH_MMIS, FETCH_MMIS_FALIUR, FETCH_MMIS_SUCCESS } from "./MMIS.type";

const initialState = {
  MMISDATA: {
    data: null,
    loading: false,
  },
};

export default function mmisReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_MMIS:
      return {
        ...state,
        MMISDATA: {
          loading: true,
        },
      };
    case FETCH_MMIS_SUCCESS:
      return {
        ...state,
        MMISDATA: {
          loading: false,
          data: action.payload,
        },
      };
    case FETCH_MMIS_FALIUR:
      return {
        ...state,
        MMISDATA: {
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
}
