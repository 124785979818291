import {
  FETCH_DEPARTMENT_LIST,
  FETCH_DEPARTMENT_LIST_FAILURE,
  FETCH_DEPARTMENT_LIST_SUCCESS,
} from "./department.type";
import { fetchDepartment } from "../../Api/api";

export const getDepartmentList = () => (dispatch) => {
  try {
    dispatch({ type: FETCH_DEPARTMENT_LIST });
    fetchDepartment()
      .then((res) => {
        dispatch({ type: FETCH_DEPARTMENT_LIST_SUCCESS, payload: res.data });
      })
      .catch((error) => {
        dispatch({ type: FETCH_DEPARTMENT_LIST_FAILURE, payload: error });
      });
  } catch (error) {
    console.log(error);
    dispatch({ type: FETCH_DEPARTMENT_LIST_FAILURE, payload: error });
  }
};
