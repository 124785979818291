import { combineReducers } from "redux";
import payerReducer from "./payer/Payer.reducer";
import officeReducer from "./office/office.reducer";
import asigneeReducer from "./Asignee/Asignee.reducer";
import agentReducer from "./Agent/Agent.reducer";
import caregiverReducer from "./Caregiver/caregiver.reducer";
import userReducer from "./user/user.reducer";
import patientReducer from "./Patient/Patient.reducer";
import agencyReducer from "./Agency/Agency.reducer";
import reportReducer from "./ReportRedux/Report.reducer";
import visitReducer from "./VisitsRedux/Visit.reducer";
import batchReducer from "./Batch/Batch.redux";
import departmentReducer from "./DepartmentRedux/department.reducer";
import NPIReducer from "./NPIRedux/NPI.reducer";
import noteReducer from "./Note/note.reducer";
import PatientActivityReducer from "./PatientActivity/PatientActivity.reducer";
import providerReducer from "./Provider/Provider.reducer";
import mmisReducer from "./MMIS/MMIS.reducer";
import feinReducer from "./FEIN/FEIN.reducer";

export default combineReducers({
  payer: payerReducer,
  office: officeReducer,
  asignee: asigneeReducer,
  agent: agentReducer,
  caregiver: caregiverReducer,
  user: userReducer,
  patient: patientReducer,
  agency: agencyReducer,
  report: reportReducer,
  visit: visitReducer,
  batch: batchReducer,
  department: departmentReducer,
  npi: NPIReducer,
  note: noteReducer,
  patientActivity: PatientActivityReducer,
  provider: providerReducer,
  mmis: mmisReducer,
  fein: feinReducer,
});
