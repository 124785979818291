import {
  GET_REPORT,
  GET_REPORT_FALIUR,
  GET_REPORT_SUCCESS,
} from "./Report.type";

const initialState = {
  reportData: null,
  loading: false,
};

export default function reportReducer(state = initialState, action) {
  switch (action.type) {
    case GET_REPORT:
      return {
        ...state,
        reportData: {
          loading: true,
        },
      };
    case GET_REPORT_SUCCESS:
      return {
        ...state,
        reportData: {
          data: action.payload,
          loading: false,
        },
      };
    case GET_REPORT_FALIUR:
      return {
        ...state,
        reportData: {
          error: action.payload,
          loading: false,
        },
      };
    default:
      return state;
  }
}
