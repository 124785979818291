import {
  FETCH_VISITS,
  FETCH_VISITS_FALIUR,
  FETCH_VISITS_SUCCESS,
  FETCH_MISSED_VISIT,
  FETCH_MISSED_VISIT_FALIUR,
  FETCH_MISSED_VISIT_SUCCESS,
  FETCH_APROVED_CLOC_IN,
  FETCH_APROVED_CLOC_IN_FAILED,
  FETCH_APROVED_CLOC_IN_SUCCESS,
} from "./Visit.type";

const initialState = {
  visitData: {
    loading: false,
    data: null,
  },
  missedVisitData: {
    loading: false,
    data: null,
  },
  aprovedClockInData: {
    loading: false,
    data: null,
  },
};

export default function visitReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_VISITS:
      return {
        ...state,
        visitData: {
          loading: true,
        },
      };
    case FETCH_VISITS_SUCCESS:
      return {
        ...state,
        visitData: {
          loading: false,
          data: action.payload,
        },
      };
    case FETCH_VISITS_FALIUR:
      return {
        ...state,
        visitData: {
          loading: false,
          error: action.payload,
        },
      };
    case FETCH_MISSED_VISIT:
      return {
        ...state,
        missedVisitData: {
          loading: true,
        },
      };
    case FETCH_MISSED_VISIT_SUCCESS:
      return {
        ...state,
        missedVisitData: {
          loading: false,
          data: action.payload,
        },
      };
    case FETCH_MISSED_VISIT_FALIUR:
      return {
        ...state,
        missedVisitData: {
          loading: false,
          error: action.payload,
        },
      };
    case FETCH_APROVED_CLOC_IN:
      return {
        ...state,
        aprovedClockInData: {
          loading: true,
        },
      };
    case FETCH_APROVED_CLOC_IN_SUCCESS:
      return {
        ...state,
        aprovedClockInData: {
          loading: false,
          data: action.payload,
        },
      };
    case FETCH_APROVED_CLOC_IN_FAILED:
      return {
        ...state,
        aprovedClockInData: {
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
}
