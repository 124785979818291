import {
  FETCH_AGENCY_PROFILE,
  FETCH_AGENCY_PROFILE_FALIUR,
  FETCH_AGENCY_PROFILE_SUCCESS,
} from "./Agency.type";
import { agency } from "../../Api/api";

export const getAgencyDetails = () => (dispatch) => {
  try {
    dispatch({ type: FETCH_AGENCY_PROFILE });
    agency("", "get")
      .then((res) => {
        dispatch({ type: FETCH_AGENCY_PROFILE_SUCCESS, payload: res.data });
      })
      .catch((error) => {
        dispatch({ type: FETCH_AGENCY_PROFILE_FALIUR, payload: error });
      });
  } catch (error) {
    console.log(error);
    dispatch({ type: FETCH_AGENCY_PROFILE_FALIUR, payload: error });
  }
};
