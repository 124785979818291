import React, { useState } from "react";
import {
  Button,
  Paper,
  Snackbar,
  Grid,
  Typography,
  TextField,
  Link,
  colors,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import logo from "../../assets/logo/logo.png";
import { useHistory } from "react-router-dom";
import { getAuth, sendSignInLinkToEmail } from "firebase/auth";
import { app } from "../../firebase/firebase";
import { checkClientUser } from "../../Api/api";

export default function ResetPassword({ screenSize, pannel }) {
  const history = useHistory();
  const [snackbar, setsnackbar] = React.useState(false);
  const [error, seterror] = useState("");
  const [email, setemail] = useState("");
  const [sendLinkSuccess, setsendLinkSuccess] = useState(false);
  const auth = getAuth(app);
  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      const variable = {
        client_user_email: email,
      };
      checkClientUser(variable).then((res) => {
        if (res.data.status === "success") {
          localStorage.setItem("email", email);
          sendSignInLinkToEmail(auth, email, {
            url: `${window.location.origin}/new-password`,
            handleCodeInApp: true,
          }).then(() => {
            console.log("link sent");
            setsendLinkSuccess(true);
          });
        } else if (res.data.status === "fail") {
          seterror("Email dosen't Register");
          setsnackbar(true);
        }
      });
      setemail("");
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Paper style={{ height: "100vh" }}>
      <Dialog
        open={sendLinkSuccess}
        onClose={() => setsendLinkSuccess(false)}
        fullWidth
      >
        <DialogContent>
          <Typography
            variant="h6"
            style={{ color: colors.green[600], marginBottom: "2rem" }}
          >
            A verification link sent to your email, please check the link &
            click to verify your account.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            style={{ float: "right" }}
            onClick={() => setsendLinkSuccess(false)}
          >
            Ok
          </Button>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={snackbar}
        onClose={() => setsnackbar(false)}
        autoHideDuration={2000}
        message="Quantity Changed"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "rgba(23, 36, 55, 1)",
            margin: "70px 20px",
          }}
        >
          <div
            style={{
              color: "white",
              fontSize: "16px",
              padding: "10px",
            }}
          >
            {error}
          </div>
        </div>
      </Snackbar>
      <Grid container>
        <Grid
          item
          sm={5}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ maxWidth: 360 }}>
            <img alt="" src={logo} width={100} />
            <Typography
              variant="h5"
              style={{ fontWeight: "bold", fontSize: "2rem" }}
            >
              Forgot Password
            </Typography>

            <form onSubmit={handleSubmit} style={{ margin: "2rem 0" }}>
              <TextField
                size="small"
                fullWidth
                variant="outlined"
                label="Email Address"
                required
                value={email}
                onChange={(e) => setemail(e.target.value)}
              />

              <Button
                style={{ marginTop: "2rem" }}
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
              >
                Submit
              </Button>
            </form>
            {/* {sendLinkSuccess && (
              <Typography
                variant="h6"
                style={{ color: colors.green[600], margin: "2rem 0" }}
              >
                A verification link sent to your email, please check the link &
                click to verify your account.
              </Typography>
            )} */}
            <Typography variant="body2">
              Already hanve an account?{" "}
              <Link
                style={{ cursor: "pointer" }}
                onClick={() => {
                  history.push("/login");
                }}
              >
                Login
              </Link>
            </Typography>
          </div>
        </Grid>
        <Grid item sm={7}>
          <div
            style={{
              width: "100%",
              height: "100vh",
              background: colors.amber[800],
            }}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}
