import { FETCH_FEIN, FETCH_FEIN_FALIUR, FETCH_FEIN_SUCCESS } from "./FEIN.type";

const initialState = {
  FEIN: {
    loading: false,
    data: null,
  },
};

export default function feinReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_FEIN:
      return {
        ...state,
        FEIN: {
          loading: true,
        },
      };
    case FETCH_FEIN_SUCCESS:
      return {
        ...state,
        FEIN: {
          loading: false,
          data: action.payload,
        },
      };
    case FETCH_FEIN_FALIUR:
      return {
        ...state,
        FEIN: {
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
}
